import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../../../css/addQuestion.module.css";
import { TextField } from "@mui/material";
import "../../../css/survey.css";
//import copy from "../../../../../assets/copy.svg";
import trash from "../../../../../assets/trash.svg";
import DropDown from "../../../../../common/DropDown";
import translations from "../../../../../config/translations.json";
import { useDispatch } from "react-redux";
import Constants from "../../../../../config";
import BasicModal from "../../../../../common/Modal";
import PrimaryButton from "../../../../../common/PrimaryButton";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
/*import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import HelpIcon from '@mui/icons-material/Help';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from "@mui/material/IconButton";*/
import { useSearchParams } from "react-router-dom";
// import { Editor, EditorState } from 'draft-js';
// import { stateFromHTML } from 'draft-js-import-html';
// import { stateToHTML } from 'draft-js-export-html';

const propTypes = {
  errorMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  surveyDetail: PropTypes.array,
  handleDelete: PropTypes.func,
  copySurveyQuestion: PropTypes.func,
  addhandleChange: PropTypes.func,
  questionDetail: PropTypes.any,
  postAddQuestion: PropTypes.func,
  previewQuestionApi: PropTypes.func,
  categoryList: PropTypes.array,
  categoryApiCall: PropTypes.func,
  subCategoryList: PropTypes.array,
  getSubCategoreyList: PropTypes.func,
  selectedValue: PropTypes.string,
  addOptionCategory: PropTypes.func,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  ikigaiDetails: PropTypes.any
};

const typeOptions = [
  "Short Text",
  "Ratings"
];
const allOptions = ["Both (Selfie,Peer)", "Selfie", "Peer Review"];

const whoCanViewOptions = ["Public", "Private"]; 

const ModelContent = ({ handleClose, deleteQuestion }) => {
  const onSave = (e) => {
    e.preventDefault();
    deleteQuestion();
  };

  return (
    <div>
      <div className="cardModalButton">
        <PrimaryButton
          text="Cancel"
          onClick={handleClose}
          className="cancelButton"
        />
        <PrimaryButton text="Yes" onClick={onSave} className="saveButton" />
      </div>
    </div>
  );
};

const QuestionField = (props) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState(props?.id ? props.id : props?.questionDetail?.type);
  /*const [questionArray, setQuestionArray] = useState([
    {
      value: "",
    },
    {
      value: "",
    },
  ]);*/
  const [textInput, settextInput] = useState("");
  const [peerOption, setPeerOption] = useState(
    props?.questionDetail?.questionVisibleTo
      ? props?.questionDetail?.questionVisibleTo
      : allOptions[0]
  );
  const [categoryOption, setcategoryOption] = useState(
    props?.questionDetail?.categoryId ? props?.questionDetail?.categoryId : ""
  );
  const [subCategoryOption, setsubCategoryOption] = useState(
    props?.questionDetail?.subCategoryId
      ? props?.questionDetail?.subCategoryId
      : ""
  );
  const [textError, setTextError] = useState(false);
  const [categoryError, setcategoryError] = useState(false);
  const [showMultiError, setMultiError] = useState(false);
  const [category, setCategory] = React.useState("");
  const [subcategory, setsubCategory] = React.useState("");
  const [open, setOpen] = useState(false);
  const [subCategoryError, setsubCategoryError] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  // const [editorState, setEditorState] = React.useState(
  //   () => EditorState.createEmpty(),
  // );
  const [isPublic, setPublic] = useState(1);
  const [ikigai, setIkigai] = useState('');
  const [keyResultArr, setKeyResultArr] = useState([]);
  const [textFieldCount, setTextFieldCount] = useState(1);
  const [lastValue, setLastValue] = useState('');


  useEffect(() => {
    if (Object.keys(props?.questionDetail).length > 0) {
      setSelectedId(props?.questionDetail?.type);
      settextInput(props?.questionDetail?.question);
      //settextInputDesc(props?.questionDetail?.questionContext);
      // setEditorState(EditorState.createWithContent(stateFromHTML(props?.questionDetail?.question)));
      setPeerOption(props?.questionDetail?.questionVisibleTo);
      //setQuestionArray(props?.questionDetail?.questionArray);
      setcategoryOption(props?.questionDetail?.categoryId);
      setCategory(props?.questionDetail?.categoryName);
      setsubCategoryOption(props?.questionDetail?.subCategoryId);
    }
  }, []);
  useEffect(() => {
    switch (props?.status) {
      case "POST_ADD_QUESTION_SUCCESS":
        props?.handleDelete(props?.value);
        props?.previewQuestionApi();
        props?.categoryApiCall();
        break;
      case "POST_ADD_QUESTION_ERROR":
        if (props.errorMessage === 'Options should be less then 9 and more then 1') {
          setMultiError(true);
        }
        break;
      case "GOAL_TITLE_DELETE_SUCCESS":
      case "GOAL_TITLE_EDIT_SUCCESS":
        props?.previewQuestionApi();
        props?.getSubCategoreyList(categoryOption, props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"));
        break;
      case "COPY_GOAL_QUESTION":
        props?.getSubCategoreyList(props?.questionDetail?.categoryId, props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"));
        break;
    }
  }, [props?.status]);

  useEffect(() => {
    setSubCategoryList(props.subCategoryList);
  }, [props?.subCategoryList]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const onChange = (e) => {
    setTextError(false);
    settextInput(e.target.value);
    setcategoryError(false);
    setsubCategoryError(false);
  };

  /*const copyQuestion = () => {
    dispatch(
      props?.copySurveyQuestion({
        question: textInput,
        questionContext: textInputDesc,
        type: selectedId,
        questionVisibleTo: peerOption,
        categoryId: categoryOption,
        subCategoryId: subCategoryOption,
        isRequired: 1,
        surveyId: props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"),
        questionArray: questionArray,
        categoryName: category,
        subCategoryName: subcategory
      })
    );
    props?.addhandleChange(selectedId);
  };*/

  const updateCategory = (value) => {
    setcategoryError(false);
    setCategory(value);
  };

  const updateSubCategory = (value) => {
    setsubCategory(value);
    setsubCategoryError(false);
  };

  const subCategoryUpdateValue = (value) => {
    setsubCategoryError(false);
    let data = props?.subCategoryList?.filter((item) => item?.name === value);
    setsubCategoryOption(data[0]?.identifier);
  };

  const addOption = (option, editIndex) => {
    if (editIndex || editIndex > -1) {
      let temp = JSON.parse(JSON.stringify(subCategoryList));
      temp[editIndex] = { name: option };
      setSubCategoryList(temp);
    } else {
      let arr = JSON.parse(JSON.stringify(subCategoryList));
      arr.push({ name: option });
      setSubCategoryList(arr);
    }
  }

  const removeLocalSubCategory = (index) => {
    let temp = JSON.parse(JSON.stringify(subCategoryList));
    temp.splice(index, 1);
    setSubCategoryList(temp);
    setsubCategoryOption('');
  }

  const categoryUpdateValue = (value) => {
    setcategoryError(false);
    var data;
    if (
      props?.categoryList?.filter((item) => item?.name === value).length > 0
    ) {
      data = props?.categoryList?.filter((item) => item?.name === value);
    }
    setsubCategoryOption('');
    props?.getSubCategoreyList(data[0]?._id, props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"));
    setcategoryOption(data[0]?._id);
  };

  const handleSetPublic = (value) => {
    value === 'Private' ? setPublic(0) : setPublic(1);
  }

  const ikigaiUpdateValue = (value) => {
    let data = props?.ikigaiDetails?.filter((item) => item?.benchmarkData === value);
    setIkigai(data[0]?.identifier);
  };

  const onSave = () => {
    let keyResult = [...keyResultArr];
    const filteredKeyResult = keyResult.filter((item) => item.text.trim() !== "");
    if (textInput === "" || textInput.trim().length < 3) {
      setTextError(true);
    } else if (categoryOption === "" && category === "") {
      setcategoryError(true);
    } else if (subCategoryOption == "" && subcategory === "") {
      setsubCategoryError(true);
    } else {
      if (
        selectedId === "Short Text" || selectedId === "shortText" ||
        selectedId === "Ratings" || selectedId === 'scale'
      ) {
        const value = {
          question: textInput,
          // questionContext: stateToHTML(editorState.getCurrentContent()),
          type: selectedId === "Short Text" || selectedId === 'shortText' ? "shortText" : "scale",
          questionVisibleTo:
            peerOption === "Both (Selfie,Peer)"
              ? "all"
              : peerOption === "Only Selfie" || peerOption === "Selfie"
                ? "onlySelfie"
                : "onlyPeerReview",
          categoryId: categoryOption,
          subCategoryId: subCategoryOption,
          isRequired: 1,
          surveyId: props?.surveyDetail?._id ? props?.surveyDetail?._id : searchParams.get("id"),
          categoryName: category,
          subCategoryName: subCategoryOption && subCategoryOption !== '' ? '' : subcategory,
          isPublic: isPublic,
          ikigai: ikigai,
          keyResults: filteredKeyResult
        };
        dispatch(props?.postAddQuestion(value));
      }
    }
  };

  /*const onChangeDesc = (e) => {
    settextInputDesc(e.target.value);
  }*/

  /*const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openCheck = Boolean(anchorEl);
  const id = openCheck ? 'simple-popper' : undefined;*/

  const handleTextFieldChange = (event, index) => {
    let arr = [...keyResultArr];
    let trimText = event.target.value.trim();
    arr[index] = {text: event.target.value, value : null}
    setKeyResultArr(arr);
    setLastValue(trimText);
  };

  const addMoreOnClick = () => {
    setTextFieldCount(textFieldCount + 1);
    setLastValue('');
  }

  const removeTextField = (i) => {
    let count = textFieldCount;
    let temp = [...keyResultArr];
    temp.splice(i, 1);
    count = count - 1;
    if(count == 0){
      count = 1;
    }
    console.log(temp);
    setLastValue(temp[temp.length -1]?.text)
    setTextFieldCount(count);
    setKeyResultArr(temp);
  }

  return (
    <div className={styles.questionFieldBox}>
      <div className={styles.isDesktopQues}>
        <div className={styles.questionFieldHeading}>
          <div className={styles.rtDesDiv}>
            <DropDown
              options={typeOptions}
              style={{ color: "#333333" }}
              updateValue={(value) => setSelectedId(value)}
              placeholder=""
              value={
                selectedId === "Short Text" || selectedId === 'shortText'
                  ? typeOptions[0]
                  : typeOptions[1]
              }
              label={'Question Type'}
            />
          </div>
          <div className={styles.rtDesDiv}>
            <DropDown
              options={whoCanViewOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => handleSetPublic(value)}
              placeholder="Private"
              value={isPublic == 1 ? "Public" : "Private" }
              label={"Who can view"}
            />
          </div>
          <div className={styles.rtDesDiv}>
            <DropDown
              options={allOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => setPeerOption(value)}
              placeholder="Peer"
              value={peerOption}
              label={'Who can respond'}
            />
          </div>
          {/*<div style={{ width: '5%' }}>
              <img
                src={trash}
                alt="trashImg"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              />
            </div>
            <div style={{ width: '5%' }}>
              <img
                src={copy}
                style={{ cursor: "pointer" }}
                alt="copyImg"
                onClick={copyQuestion}
              />
              </div>*/}
        </div>
      </div>
      <div className={styles.isMobileQues}>
        <div className={styles.questionFieldHeading}>
          <div className={styles.questionFieldHeadingLt}>
            <DropDown
              options={typeOptions}
              style={{ color: "#333333" }}
              updateValue={(value) => setSelectedId(value)}
              placeholder=""
              value={
                selectedId === "Short Text" || selectedId === 'shortText'
                  ? typeOptions[0]
                  : typeOptions[1]
              }
              label={'Question Type'}
            />
          </div>
          <div className={styles.isDesktopQues}>
            <div className={styles.questionFieldHeadingRt}>
              <div style={{ width: '5%' }}>
                <img
                  src={trash}
                  alt="trashImg"
                  style={{ cursor: "pointer" }}
                  onClick={handleClickOpen}
                />
              </div>
              {/*
              <div style={{ width: '5%' }}>
                <img
                  src={copy}
                  style={{ cursor: "pointer" }}
                  alt="copyImg"
                  onClick={copyQuestion}
                />
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.questionFieldBody}>
        <div className="flex-space">
          <div className={styles.isDesktopQues} style={{ width: '100%'}}>
            <div style={{display: 'flex'}}>
              <div style={{ width: '30%' }} className={styles.rtDesDiv}>
                <DropDown
                  options={props?.categoryList}
                  style={{ color: "#14009B" }}
                  updateValue={categoryUpdateValue}
                  addOption={(data) => props.addOptionCategory(data)}
                  placeholder="Objective Type"
                  value={categoryOption}
                  updateCategory={updateCategory}
                  label={"Objective Type"}
                />
              </div>
              <div style={{ width: '32%', marginLeft:'15px' }}>
                <DropDown
                  options={props?.categoryList.length && (categoryOption !== '' || category !== '') ? subCategoryList : []}
                  style={{ color: "#14009B" }}
                  updateValue={(value) => {
                    subCategoryUpdateValue(value);
                  }}
                  addOption={addOption}
                  removeLocalSubCategory={removeLocalSubCategory}
                  placeholder="Objective Title"
                  value={subCategoryOption}
                  updateCategory={updateSubCategory}
                  label={'Objective Title'}
                  hideAddBtn={!props?.categoryList.length || (categoryOption === '' && category === '')}
                  deleteGoalTitle={props?.deleteGoalTitle}
                  editGoalTitle={props?.editGoalTitle}
                />
              </div>
              <div className={styles.rtDesDiv} style={{width:'30%', marginLeft:'27px'}}>
                <DropDown
                  options={props?.ikigaiDetails}
                  style={{ color: "#14009B" }}
                  updateValue={(value) => ikigaiUpdateValue(value)}
                  placeholder="Ikigai"
                  value={ikigai}
                  label={'Ikigai'}
                />
              </div> 
            </div>
          </div>
          <div className={styles.isDesktopQues} style={{ width: '30%' }}>
            <div className={styles.questionFieldSave}>
              <span
                className={styles.questionCancelText}
                onClick={() => props.handleDelete(props.value)}
              >
                {Constants.CANCEL}
              </span>
              <Button className={styles.questionSaveText} onClick={onSave}>
                {Constants.SAVE}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.isMobileQues}>
          <DropDown
            options={props?.categoryList}
            style={{ color: "#14009B" }}
            updateValue={categoryUpdateValue}
            placeholder="Objective Type"
            value={categoryOption}
            updateCategory={updateCategory}
            addOption={(data) => props.addOptionCategory(data)}
            label={'Objective Type'}
          />
          <DropDown
            options={props?.categoryList.length && (categoryOption !== '' || category !== '') ? subCategoryList : []}
            style={{ color: "#14009B" }}
            updateValue={(value) => {
              subCategoryUpdateValue(value);
            }}
            addOption={addOption}
            removeLocalSubCategory={removeLocalSubCategory}
            placeholder="Objective Title"
            value={subCategoryOption}
            updateCategory={updateSubCategory}
            label={'Objective Title'}
            hideAddBtn={!props?.categoryList.length || (categoryOption === '' && category === '')}
            deleteGoalTitle={props?.deleteGoalTitle}
            editGoalTitle={props?.editGoalTitle}
          />
          <DropDown
              options={allOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => setPeerOption(value)}
              placeholder="Peer"
              value={peerOption}
              label={'Who can respond'}
          />
          <DropDown
              options={whoCanViewOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => handleSetPublic(value)}
              placeholder="Private"
              value={isPublic == 1 ? "Public" : "Private" }
              label={"Who can view"}
          />
          <div className={styles.rtDesDiv}>
            <DropDown
              options={props?.ikigaiDetails}
              style={{ color: "#14009B" }}
              updateValue={(value) => ikigaiUpdateValue(value)}
              placeholder="Ikigai"
              value={ikigai}
              label={'Ikigai'}
            />
          </div> 
        </div>
        <div className={styles.questionFieldFlex}>
          <div className={styles.halfWidthBoxLt}>
            <div className={styles.questionFieldTitle}>{Constants.ENTER_GOAL}</div>
            <div className={styles.questionFieldRightBox + " questionFieldTextArea"}>
              <TextField
                fullWidth
                placeholder="Start Portfolio management webinar in Q2 to generate leads"
                variant="outlined"
                type="text"
                value={textInput}
                onChange={onChange}
                inputProps={{ maxLength: 500 }}
                error={textError}
                autoComplete="off"
                helperText={textError ? Constants.VALID_GOAL : null}
                multiline
                rows={textFieldCount< 3 ? 3 : textFieldCount + 2}
              />
              {categoryError ? (
                <div
                  className={styles.errorMessage}
                  style={{ marginTop: "10px" }}
                >
                  {translations.errorMessage.categoryField[0]}
                </div>
              ) : (
                ""
              )}
              {subCategoryError ? (
                <div
                  className={styles.errorMessage}
                  style={{ marginTop: "10px" }}
                >
                  {translations.errorMessage.subCategoryField[0]}
                </div>
              ) : (
                ""
              )}
              {/*<div className={styles.questionFieldFlex}>
                <div className={styles.questionFieldCheckBox}>
                  <CheckBox checked={checked} handleChange={handleChange} />{" "}
                  {Constants.MANDATORY}
                </div>
              </div>*/}
            </div>
          </div>
          <div className={styles.halfWidthBoxRt}>
            <div className={styles.questionFieldTitle}>
              {Constants.ENTER_EXPLAINATION}
              {/*<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <IconButton aria-describedby={id} onClick={handleClick} style={{ marginTop: '-7px' }}>
                    <HelpIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                  <Popper id={id} open={openCheck} anchorEl={anchorEl}>
                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', fontSize: '12px' }}>
                      Add an optional supporting explaination to the goal which will be visible during the review
                    </Box>
                  </Popper>
                </Box>
              </ClickAwayListener>*/}
            </div>
            <div className={styles.questionFieldRightBox}>
              {/*<TextField
                fullWidth
                placeholder="1. 10% leads are accepted by sales&#10;2. Double the product demos participants"
                variant="outlined"
                type="text"
                value={textInputDesc}
                onChange={onChangeDesc}
                inputProps={{ maxLength: 1000 }}
                autoComplete="off"
                multiline
                rows={3}
            />*/}
            {Array.from({ length: textFieldCount }, (_, i) => (
              <div style={{display:'flex'}}>
                <TextField
                  key={i}
                  id={i}
                  label={keyResultArr[i] ? "" : "Type Here..."}
                  // multiline
                  // maxRows={10}
                  autoComplete="off"
                  variant="standard"
                  value={keyResultArr[i] ? keyResultArr[i]?.text : ''}
                  style={{ width: props.isMobileLayout ? '90%' : '100%', padding: '0px 0px', color: '#000000', marginLeft: props.isMobileLayout ? '22px' : null }}
                  onChange={(event) => handleTextFieldChange(event, i)}
                />
                {keyResultArr[i]?.text ? 
                  <ClearIcon style={{cursor: 'pointer', fontSize:'18px', marginTop: '14px'}} onClick={() => removeTextField(i)}/>
                  :null}
              </div>
            ))}
            { lastValue ? 
              <div>
                <button 
                  className={styles.addMoreKeyResults} 
                  style={{ marginLeft: props.isMobileLayout ? '0px' : '-5px', marginTop: '5px' }} 
                  onClick={() => addMoreOnClick()}
                > + Add more</button>
              </div> : null }
              {/* <Editor editorState={editorState} onChange={setEditorState} placeholder={'1. 10% leads are accepted by sales 2. Double the product demos participants'} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.isMobileQues} style={{ padding: '0 10px 10px 10px' }}>
        <div className="flex-space">
          <div className={styles.questionFieldHeadingRt} style={{ display: 'flex', justifyContent: 'normal' }}>
            <div style={{ width: '30%' }}>
              <img
                src={trash}
                alt="trashImg"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              />
            </div>
            {/*
            <div style={{ width: '30%' }}>
              <img
                src={copy}
                style={{ cursor: "pointer" }}
                alt="copyImg"
                onClick={copyQuestion}
              />
            </div>
            */}
          </div>
          <div className={styles.questionFieldSave}>
            <span
              className={styles.questionCancelText}
              onClick={() => props.handleDelete(props.value)}
            >
              {Constants.CANCEL}
            </span>
            <Button className={styles.questionSaveText} onClick={onSave}>
              {Constants.SAVE}
            </Button>
          </div>
        </div>
      </div>
      {open ? (
        <BasicModal
          open={open}
          handleClose={handleClose}
          heading="Are you sure you want to delete this objective ?"
          content={
            <ModelContent
              handleClose={handleClose}
              deleteQuestion={() => props.handleDelete(props.value)}
            />
          }
        />
      ) : (
        <></>
      )}
      {
        showMultiError ?
          <ToastNotify
            data={props.errorMessage}
            openAlert={true}
            type={"error"}
          /> : null
      }
    </div>
  );
};
QuestionField.propTypes = propTypes;
export default QuestionField;
