import React, { useEffect, useState } from "react";
import BasicModal from "../Modal";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Field from "../Field";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import translations from "../../config/translations.json";
import PrimaryButton from "../PrimaryButton";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { userPasswordChange } from "../../modules/auth/store/actions";
import ToastNotify from "../Toast/ToastNotify";
import Constants from "../../config";
import Loader from "../loader/loader";
import LoaderLogout from "../loader/index";
import { getUserDetails, userLogout, setDummyReset } from "../../modules/auth/store/actions";
import Stack from "@mui/material/Stack";

const propTypes = {
  anchorElUser: PropTypes.any,
  handleCloseUserMenu: PropTypes.func.isRequired,
  userDetail: PropTypes.any,
  isNew: PropTypes.bool,
  getUserDetails: PropTypes.func,
  userLogout: PropTypes.func,
  setDummyReset: PropTypes.func
};

const ModelContent = (props) => {
  const dispatch = useDispatch();
  const [formObj, setData] = useState({
    currentPassword: { value: "", error: false },
    newPassword: { value: "", error: false },
  });
  const [currentPasswordError, setIsCurrentPasswordError] = useState(false);
  const [newPasswordError, setIsNewPasswordError] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showError, setError] = useState(false);

  useEffect(() => {
    switch (props.status) {
      case "USER_PASSWORD_CHANGE_ERROR":
        setError(true);
        setTimeout(() => setError(false), 2000);
        break;
    }
  }, [props.status]);
  const handleClickShowPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onChange = (event) => {
    const inputValue = event.target.value.replace(/\s/g, "");
    setData({
      ...formObj,
      [event.target.name]: { value: inputValue, error: false },
    });
    setIsCurrentPasswordError(false);
    setIsNewPasswordError(false);
    setError(false);
  };
  const checkPasswordValid = (validateData) => {
    const minLength = 6;
    const maxLength = 20;
    const password = validateData.trim();
    const fieldValid = new RegExp(
      "^.{" + minLength + "," + maxLength + "}$"
    ).test(password);
    return fieldValid;
  };
  const onSave = (e) => {
    e.preventDefault();
    setData({
      ...formObj,
      currentPassword: {
        ...formObj["currentPassword"],
        error: formObj["currentPassword"].value === "",
      },
      newPassword: {
        ...formObj["newPassword"],
        error: formObj["newPassword"].value === "" || formObj["newPassword"].value.length < 8,
      },
    });
    if (
      formObj["currentPassword"].value.trim() !== "" &&
      formObj["newPassword"].value.trim() !== "" && formObj["newPassword"].value.trim().length >= 8
    ) {
      if (
        checkPasswordValid(formObj["currentPassword"].value) &&
        checkPasswordValid(formObj["newPassword"].value)
      ) {
        dispatch(
          userPasswordChange({
            currentPassword: formObj["currentPassword"].value,
            newPassword: formObj["newPassword"].value,
          })
        );
      } else {
        if (!checkPasswordValid(formObj["currentPassword"].value) && !checkPasswordValid(formObj["newPassword"].value)) {
          setIsCurrentPasswordError(true);
          setIsNewPasswordError(true);
        } else if (!checkPasswordValid(formObj["currentPassword"].value)) {
          setIsCurrentPasswordError(true);
        } else {
          setIsNewPasswordError(true);
        }
      }
    }
  };
  return (
    <div className="passwordChangeField">
      <Stack>
        <div className="date-label-custom-other">{Constants.CURRENT_PASSWORD}</div>
        <Field
          placeholder={Constants.ENTER_CURRENT_PASSWORD}
          type={showCurrentPassword ? "text" : "password"}
          name={"currentPassword"}
          value={formObj.currentPassword.value}
          error={formObj.currentPassword.error || currentPasswordError}
          helperText={
            formObj.currentPassword.error
              ? translations.errorMessage.password3[0]
              : currentPasswordError
                ? translations.errorMessage.password2[0]
                : null
          }
          onChange={onChange}
          endadornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          onKeyUp={(event) => {
            if (event.key === "Enter" && event.target.value.trim() !== "") {
              onSave(event);
            }
          }}
        />
      </Stack>
      <Stack>
        <div className="date-label-custom-other">{Constants.NEW_PASSWORD}</div>
        <Field
          placeholder={Constants.ENTER_NEW_PASSWORD}
          type={showNewPassword ? "text" : "password"}
          name={"newPassword"}
          value={formObj.newPassword.value}
          error={formObj.newPassword.error || newPasswordError}
          helperText={
            formObj.newPassword.error
              ? translations.errorMessage.password3[0]
              : newPasswordError
                ? translations.errorMessage.password2[0]
                : null
          }
          onChange={onChange}
          endadornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          onKeyUp={(event) => {
            if (event.key === "Enter" && event.target.value.trim() !== "") {
              onSave(event);
            }
          }}
        />
      </Stack>
      <div>
        {
          showError ?
            <ToastNotify
              data={props.errorMessage}
              openAlert={true}
              type={"error"}
            />
            : null
        }
        <div className="cardModalButton">
          {
            !props?.hideCancel ?
              <PrimaryButton
                text={Constants.CANCEL}
                onClick={props.handleCloseModal}
                className="cancelButton"
              /> : null
          }
          <PrimaryButton text={Constants.SAVE} onClick={onSave} className="saveButton" />
        </div>
        <div className="passwordRules">
          <p><span style={{ marginRight: '3px' }}>*</span> <span>Password must be of minimum 8 characters</span></p>
          <p><span style={{ marginRight: '3px' }}>*</span> <span>Password should not start with special characters</span></p>
          <p><span style={{ marginRight: '3px' }}>*</span> <span>Password must contain at least one upper case letter,one lower case letter,one number and a special character which can be ! @ # $ ^ % ( ) + _ &amp;</span></p>
        </div>
      </div>
    </div>
  );
};

const ProfileMenu = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [hideCancel, setHideCancel] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [loaderLogout, setLoaderLogout] = useState(false);

  useEffect(() => {
    switch (props.status) {
      case "USER_LOGOUT":
        setLoaderLogout(true);
        break;
      case "USER_LOGOUT_SUCCESS":
        setLoaderLogout(false);
        setOpenLogout(false);
        window.localStorage.clear();
        navigate("/");
        break;
      case "USER_LOGOUT_ERROR":
        setLoaderLogout(false);
        break;
      case "USER_PASSWORD_CHANGE":
        setLoader(true);
        break;
      case "USER_PASSWORD_CHANGE_SUCCESS":
        setLoader(false);
        handleCloseModal();
        setSuccess(true);
        props.setDummyReset();
        setTimeout(() => setSuccess(false), 2000);
        if (props?.isNew) {
          props.getUserDetails();
        }
        break;
      case "USER_PASSWORD_CHANGE_ERROR":
        setLoader(false);
        break;
    }
  }, [props.status]);

  useEffect(() => {
    if (props?.isNew) {
      setOpen(true);
    }
    setHideCancel(props?.isNew);
  }, [props?.isNew])

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
    props.handleCloseUserMenu();
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const logoutFun = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false)
  }

  return (
    <div className="profileMenu">
      <Loader is_loading={isLoading} />
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={props.anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(props.anchorElUser)}
        onClose={props.handleCloseUserMenu}
      >
        {
          props.userDetail && props.userDetail.email && props.userDetail.email !== '' ?
            <MenuItem>
              <Typography
                textAlign="center"
                style={{ fontWeight: "500", fontSize: "12px", fontStyle: 'italic', color: '#7d7d7d', cursor: 'default' }}
              >
                {props.userDetail.email}
              </Typography>
            </MenuItem> : null
        }
        <MenuItem onClick={handleClickOpen}>
          <Typography
            textAlign="center"
            style={{ fontWeight: "500", fontSize: "14px" }}
          >
            {Constants.CHANGE_PASSWORD}
          </Typography>
        </MenuItem>
        <MenuItem onClick={logoutFun}>
          <Typography
            textAlign="center"
            style={{ fontWeight: "500", fontSize: "14px" }}
          >
            {Constants.LOGOUT}
          </Typography>
        </MenuItem>
      </Menu>
      <BasicModal
        open={open}
        handleCloseModal={handleCloseModal}
        heading={Constants.CHANGE_PASSWORD}
        hideCancel={hideCancel}
        content={
          <ModelContent
            handleCloseModal={handleCloseModal}
            errorMessage={props.errorMessage}
            isLoading={props.isLoading}
            status={props.status}
            hideCancel={hideCancel}
          />
        }
      />
      {
        showSuccess ?
          <ToastNotify
            data={translations.successNotification.passwordUpdate[0]}
            openAlert={true}
            type={"success"}
          /> : null
      }
      {openLogout ? (
        <BasicModal
          open={openLogout}
          handleClose={handleCloseLogout}
          heading="Are you sure you want to logout?"
          content={
            <div className="cardModalButton">
              <PrimaryButton
                text={Constants.CANCEL}
                onClick={handleCloseLogout}
                className="cancelButton"
              />
              <div style={{ position: 'relative', width: '30%' }}>
                <LoaderLogout isLoading={loaderLogout} />
                <PrimaryButton
                  text={Constants.LOGOUT}
                  onClick={() => {
                    props.userLogout();
                  }}
                  className="saveButtonOther" />
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
}
ProfileMenu.propTypes = propTypes;
const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    userDetail: state.auth.userDetail,
    isLoading: state.auth.isLoading,
    isNew: state.auth.isNew,
    status: state.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => {
      dispatch(getUserDetails());
    },
    userLogout: () => {
      dispatch(userLogout());
    },
    setDummyReset: () => {
      dispatch(setDummyReset());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
