import React, { useEffect, useState } from "react";
import "../../../css/survey.css";
import styles from "../../../css/addQuestion.module.css";
import { TextField } from "@mui/material";
import "../../../css/survey.css";
//import copy from "../../../../../assets/copy.svg";
import DropDown from "../../../../../common/DropDown";
import translations from "../../../../../config/translations.json";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Constants from "../../../../../config";
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
// import { Editor, EditorState } from 'draft-js';
// import { stateFromHTML } from 'draft-js-import-html';
// import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
/*import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import HelpIcon from '@mui/icons-material/Help';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from "@mui/material/IconButton";*/
import parse from 'html-react-parser';

const propTypes = {
  errorMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  surveyDetail: PropTypes.array,
  copySurveyQuestion: PropTypes.func,
  addhandleChange: PropTypes.func,
  questionDetail: PropTypes.object,
  previewQuestionApi: PropTypes.func,
  categoryList: PropTypes.array,
  categoryApiCall: PropTypes.func,
  title: PropTypes.string,
  details: PropTypes.object,
  editQuestions: PropTypes.func,
  seteditValue: PropTypes.func,
  subCategoryList: PropTypes.array,
  getSubCategoreyList: PropTypes.func,
  onClose: PropTypes.func,
  addOptionCategory: PropTypes.func,
  deleteGoalTitle: PropTypes.func,
  editGoalTitle: PropTypes.func,
  ikigaiDetails: PropTypes.array
};

const typeOptions = [
  "Short Text",
  "Ratings"
];
const allOptions = ["Both (Selfie,Peer)", "Selfie", "Peer Review"];

const whoCanViewOptions = ["Public", "Private"];

const EditQuestions = (props) => {
  const dispatch = useDispatch();
  // let contentState = stateFromHTML(props?.details?.questionContext);
  const [selectedId, setSelectedId] = useState(
    props?.details?.type === "shortText"
      ? "Short Text"
      : "Ratings"
  );
  const [textInput, settextInput] = useState(props?.details?.question);
  const [peerOption, setPeerOption] = useState(
    props?.details?.questionVisibleTo === "all"
      ? "Both (Selfie,Peer)"
      : props?.details?.questionVisibleTo === "onlySelfie"
        ? "Selfie"
        : "Peer Review"
  );
  const [categoryOption, setcategoryOption] = useState(
    props?.details?.categoryId
  );
  const [subCategoryOption, setsubCategoryOption] = useState(
    props?.details?.subCategoryId
  );
  const [textError, setTextError] = useState(false);
  const [categoryError, setcategoryError] = useState(false);
  const [category, setCategory] = React.useState("");
  const [subcategory, setsubCategory] = React.useState("");
  const [subCategoryError, setsubCategoryError] = React.useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);

  // const [editorState, setEditorState] = React.useState(
  //   () => EditorState.createWithContent(contentState),
  // );

  const [isPublic, setPublic] = useState(
    props?.details?.isPublic === 1 ? 1 : 0
  );

  const [ikigai, setIkigai] = useState(props?.details?.ikigai);
  const [textFieldCount, setTextFieldCount] = useState(props?.details?.keyResults?.length ? props?.details?.keyResults?.length : 1);
  const [keyResultArr, setKeyResultArr] = useState(props?.details?.keyResults ? props?.details?.keyResults : []);
  const [lastValue, setLastValue] = useState(props?.details?.keyResults ? props?.details?.keyResults[props?.details?.keyResults?.length - 1]?.text : '');

  useEffect(() => {
    switch (props?.status) {
      case "EDIT_QUESTIONS_SUCCESS":
        props?.previewQuestionApi();
        props?.seteditValue(null);
        setKeyResultArr(null);
        break;
      case "GOAL_TITLE_DELETE_SUCCESS":
      case "GOAL_TITLE_EDIT_SUCCESS":
        props?.previewQuestionApi();
        props?.getSubCategoreyList(categoryOption, props?.surveyDetail?._id);
        break;
    }
  }, [props?.status]);
  useEffect(() => {
    setSubCategoryList(props.subCategoryList);
  }, [props?.subCategoryList]);
  useEffect(() => {
    props?.getSubCategoreyList(props?.details?.categoryId, props?.surveyDetail?._id);
  }, []);
  const addButtonFunc = () => { };
  const onChange = (e) => {
    setTextError(false);
    settextInput(e.target.value);
  };
  /*const onChangeDesc = (e) => {
    settextInputDesc(e.target.value);
  };
  const copyQuestion = () => {
    dispatch(
      props?.copySurveyQuestion({
        question: textInput,
        questionContext: textInputDesc,
        type: selectedId,
        questionVisibleTo: peerOption,
        categoryId: categoryOption,
        subCategoryId: subCategoryOption,
        isRequired: 1,
        surveyId: props?.surveyDetail?._id,
        questionArray: [],
        categoryName: category,
      })
    );
    props?.addhandleChange(selectedId);
  };*/

  const updateSubCategory = (value) => {
    setsubCategory(value);
    setsubCategoryError(false);
  };

  const subCategoryUpdateValue = (value) => {
    setsubCategoryError(false);
    let data = props?.subCategoryList?.filter((item) => item?.name === value);
    setsubCategoryOption(data[0]?.identifier);
  };

  const addOption = (option, editIndex) => {
    if (editIndex || editIndex > -1) {
      let temp = JSON.parse(JSON.stringify(subCategoryList));
      temp[editIndex] = { name: option };
      setSubCategoryList(temp);
    } else {
      let arr = JSON.parse(JSON.stringify(subCategoryList));
      arr.push({ name: option });
      setSubCategoryList(arr);
    }
  }

  const removeLocalSubCategory = (index) => {
    let temp = JSON.parse(JSON.stringify(subCategoryList));
    temp.splice(index, 1);
    setSubCategoryList(temp);
    setsubCategoryOption('');
  }

  const updateCategory = (value) => {
    setcategoryError(false);
    setCategory(value);
    setsubCategoryOption('');
  };

  const handleSetPublic = (value) => {
    value === 'Private' ? setPublic(0) : setPublic(1);
  }

  const ikigaiUpdateValue = (value) => {
    let data = props?.ikigaiDetails?.filter((item) => item?.benchmarkData === value);
    setIkigai(data[0]?.identifier);
  };

  const categoryUpdateValue = (value) => {
    setcategoryError(false);
    var data;
    if (
      props?.categoryList?.filter((item) => item?.name === value).length > 0
    ) {
      data = props?.categoryList?.filter((item) => item?.name === value);
    }
    props?.getSubCategoreyList(data[0]?._id, props?.surveyDetail?._id);
    setcategoryOption(data[0]?._id);
    setsubCategoryOption('');
  };
  const onSave = () => {
    let keyResult = [...keyResultArr];
    const filteredKeyResult = keyResult.filter(item => item.text.trim() !== "");
    if (textInput === "" || textInput.trim().length < 3) {
      setTextError(true);
    } else if (categoryOption === "" && category === "") {
      setcategoryError(true);
    } else if (subCategoryOption == "" && subcategory === "") {
      setsubCategoryError(true);
    } else {
      if (
        selectedId === "Short Text" || selectedId === 'shortText' ||
        selectedId === "Ratings" || selectedId === 'scale'
      ) {
        const value = {
          question: textInput,
          // questionContext: stateToHTML(editorState.getCurrentContent()),
          type: selectedId === "Short Text" || selectedId === 'shortText' ? "shortText" : "scale",
          questionVisibleTo:
            peerOption === "Both (Selfie,Peer)"
              ? "all"
              : peerOption === "Only Selfie" || peerOption === "Selfie"
                ? "onlySelfie"
                : "onlyPeerReview",
          categoryId: categoryOption,
          subCategoryId: subCategoryOption,
          isRequired: 1,
          questionId: props?.details?.identifier,
          categoryName: category,
          subCategoryName: subCategoryOption && subCategoryOption !== '' ? '' : subcategory,
          isPublic: isPublic,
          ikigai: ikigai,
          keyResults: filteredKeyResult
        };
        dispatch(props?.editQuestions(value));
      }
    }
  };

  const handleTextFieldChange = (event, index) => {
    let arr = [...keyResultArr];
    let value = arr[index]?.value ? arr[index].value : null;
    let trimText = event.target.value.trim();
    arr[index] = { text: event.target.value, value: value };
    setKeyResultArr(arr);
    setLastValue(trimText);
  };

  const addMoreOnClick = () => {
    setTextFieldCount(textFieldCount + 1);
    setLastValue('');
  }

  const removeTextField = (i) => {
    let count = textFieldCount;
    let temp = [...keyResultArr];
    temp.splice(i, 1);
    count = count - 1;
    if (count == 0) {
      count = 1;
    }
    console.log(temp);
    setLastValue(temp[temp.length - 1]?.text)
    setTextFieldCount(count);
    setKeyResultArr(temp);
  }
  /*const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openCheck = Boolean(anchorEl);
  const id = openCheck ? 'simple-popper' : undefined;*/

  return (
    <div className={styles.questionFieldBox}>
      <div className={styles.isDesktopQues}>
        <div className={styles.questionFieldHeading}>
          <div className={styles.rtDesDiv}>
            <DropDown
              options={typeOptions}
              style={{ color: "#333333" }}
              updateValue={(value) => setSelectedId(value)}
              placeholder=""
              value={
                selectedId === "Short Text" || selectedId === 'shortText'
                  ? typeOptions[0]
                  : typeOptions[1]
              }
              label={'Question Type'}
              hideAddBtn={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
            />
          </div>

          <div className={styles.rtDesDiv}>
            <DropDown
              options={whoCanViewOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => handleSetPublic(value)}
              placeholder=""
              value={isPublic == 1 ? "Public" : "Private"}
              label={'Who can view'}
            />
          </div>

          <div className={styles.rtDesDiv}>
            <DropDown
              options={allOptions}
              style={{ color: "#14009B" }}
              updateValue={(value) => setPeerOption(value)}
              placeholder=""
              value={peerOption}
              label={'Who can respond'}
              hideAddBtn={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
            />
          </div>
          {/*<div style={{ width: "5%" }}>
              <img
                src={copy}
                style={{ cursor: "pointer" }}
                alt="copyImg"
                onClick={() => { copyQuestion(); props?.onClose(); }}
              />
              </div>*/}
        </div>
      </div>
      <div className={styles.isMobileQues}>
        <div className={styles.questionFieldHeading}>
          <div className={styles.questionFieldHeadingLt}>
            <DropDown
              options={typeOptions}
              style={{ color: "#333333" }}
              updateValue={(value) => setSelectedId(value)}
              placeholder=""
              value={
                selectedId === "Short Text" || selectedId === 'shortText'
                  ? typeOptions[0]
                  : typeOptions[1]
              }
              label={'Question Type'}
              hideAddBtn={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
            />
          </div>
          {/*
          <div className={styles.isDesktopQues}>
            <div className={styles.questionFieldHeadingRt}>
              <img
                src={copy}
                style={{ cursor: "pointer" }}
                alt="copyImg"
                onClick={copyQuestion}
              />
            </div>
          </div>
            */}
        </div>
      </div>
      <div className={styles.questionFieldBody}>
        <div className="flex-space">
          <div className={styles.isDesktopQues} style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '30%' }} className={styles.rtDesDiv}>
                <DropDown
                  options={props?.categoryList}
                  style={{ color: "#14009B" }}
                  updateValue={categoryUpdateValue}
                  addOption={(data) => props.addOptionCategory(data)}
                  placeholder="Objective Type"
                  value={categoryOption}
                  addButton={addButtonFunc}
                  updateCategory={updateCategory}
                  label={'Objective Type'}
                  hideAddBtn={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
                />
              </div>
              <div style={{ width: '32%', marginLeft: '15px' }}>
                <DropDown
                  options={
                    props?.categoryList.length && categoryOption !== '' ? subCategoryList : []
                  }
                  style={{ color: "#14009B" }}
                  updateValue={subCategoryUpdateValue}
                  placeholder="Objective Title"
                  value={subCategoryOption}
                  updateCategory={updateSubCategory}
                  label={'Objective Title'}
                  addOption={addOption}
                  removeLocalSubCategory={removeLocalSubCategory}
                  hideAddBtn={!props?.categoryList.length || categoryOption === '' || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
                  deleteGoalTitle={props?.deleteGoalTitle}
                  editGoalTitle={props?.editGoalTitle}
                />
              </div>
              <div className={styles.rtDesDiv} style={{ width: '30%', marginLeft: '27px' }}>
                <DropDown
                  options={props?.ikigaiDetails}
                  style={{ color: "#14009B" }}
                  updateValue={(value) => ikigaiUpdateValue(value)}
                  placeholder="Ikigai"
                  value={ikigai}
                  label={'Ikigai'}
                />
              </div>
            </div>
          </div>
          <div className={styles.isDesktopQues} style={{ width: '30%' }}>
            <div className={styles.questionFieldSave}>
              <span
                className={styles.questionCancelText}
                onClick={props?.onClose}
              >
                {Constants.CANCEL}
              </span>
              <Button className={styles.questionSaveText} onClick={onSave}>
                {Constants.SAVE}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.isMobileQues}>
          <DropDown
            options={props?.categoryList}
            style={{ color: "#14009B" }}
            updateValue={categoryUpdateValue}
            placeholder="Objective Type"
            value={categoryOption}
            addButton={addButtonFunc}
            updateCategory={updateCategory}
            addOption={(data) => props.addOptionCategory(data)}
            label={'Objective Type'}
            hideAddBtn={props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
          />
          <DropDown
            options={props?.categoryList.length && categoryOption !== '' ? subCategoryList : []}
            style={{ color: "#14009B" }}
            updateValue={subCategoryUpdateValue}
            placeholder="Objective Title"
            value={subCategoryOption}
            updateCategory={updateSubCategory}
            hideAddBtn={!props?.categoryList.length || categoryOption === '' || props?.surveyDetail?.status === 'PUBLISHED' || props?.surveyDetail?.status === 'SCHEDULED'}
            addOption={addOption}
            removeLocalSubCategory={removeLocalSubCategory}
            label={'Objective Title'}
            deleteGoalTitle={props?.deleteGoalTitle}
            editGoalTitle={props?.editGoalTitle}
          />
          <DropDown
            options={allOptions}
            style={{ color: "#14009B" }}
            updateValue={(value) => setPeerOption(value)}
            placeholder=""
            value={peerOption}
            label={'Who can respond'}
          />
          <DropDown
            options={whoCanViewOptions}
            style={{ color: "#14009B" }}
            updateValue={(value) => handleSetPublic(value)}
            placeholder=""
            value={isPublic == 1 ? "Public" : "Private"}
            label={'Who can view'}
          />
          <div className={styles.rtDesDiv}>
            <DropDown
              options={props?.ikigaiDetails}
              style={{ color: "#14009B" }}
              updateValue={(value) => ikigaiUpdateValue(value)}
              placeholder="Ikigai"
              value={ikigai}
              label={'Ikigai'}
            />
          </div>
        </div>
        <div className={styles.questionFieldFlex}>
          <div className={styles.halfWidthBoxLt}>
            <div className={styles.questionFieldTitle}>{Constants.ENTER_GOAL}</div>
            <div className={styles.questionFieldRightBox + " questionFieldTextArea"}>
              <TextField
                fullWidth
                placeholder="Start Portfolio management webinar in Q2 to generate leads"
                variant="outlined"
                type="text"
                value={textInput}
                onChange={onChange}
                error={textError}
                inputProps={{ maxLength: 500 }}
                helperText={
                  textError ? Constants.VALID_GOAL : null
                }
                multiline
                rows={textFieldCount < 3 ? 3 : textFieldCount + 2}
              />
              {categoryError ? (
                <div
                  className={styles.errorMessage}
                  style={{ marginTop: "10px" }}
                >
                  {translations.errorMessage.categoryField[0]}
                </div>
              ) : (
                ""
              )}
              {subCategoryError ? (
                <div
                  className={styles.errorMessage}
                  style={{ marginTop: "10px" }}
                >
                  {translations.errorMessage.subCategoryField[0]}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.halfWidthBoxRt}>
            <div className={styles.questionFieldTitle}>
              {Constants.ENTER_EXPLAINATION}
              {/*<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <IconButton aria-describedby={id} onClick={handleClick} style={{ marginTop: '-7px' }}>
                    <HelpIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                  <Popper id={id} open={openCheck} anchorEl={anchorEl}>
                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', fontSize: '12px' }}>
                      Add an optional supporting explaination to the goal which will be visible during the review
                    </Box>
                  </Popper>
                </Box>
              </ClickAwayListener>*/}
            </div>
            <div className={styles.questionFieldRightBox}>
              {/*<TextField
                fullWidth
                placeholder="1. 10% leads are accepted by sales&#10;2. Double the product demos participants"
                variant="outlined"
                type="text"
                value={textInputDesc}
                onChange={onChangeDesc}
                inputProps={{ maxLength: 1000 }}
                autoComplete="off"
                multiline
                rows={3}
            />*/}
              {Array.from({ length: textFieldCount }, (_, i) => (
                <div style={{ display: 'flex' }}>
                  <TextField
                    key={i}
                    id={i}
                    label={keyResultArr[i] ? "" : "Type Here..."}
                    // multiline
                    // maxRows={10}
                    autoComplete="off"
                    variant="standard"
                    value={keyResultArr[i] ? parse(keyResultArr[i]?.text) : ''}
                    style={{ width: props.isMobileLayout ? '90%' : '100%', padding: '0px 0px', color: '#000000', marginLeft: props.isMobileLayout ? '22px' : null }}
                    onChange={(event) => handleTextFieldChange(event, i)}
                  />
                  {keyResultArr[i]?.text ?
                    <ClearIcon style={{ cursor: 'pointer', fontSize: '18px', marginTop: '14px' }} onClick={() => removeTextField(i)} />
                    : null}
                </div>
              ))}
              {lastValue ?
                <div>
                  <button
                    className={styles.addMoreKeyResults}
                    style={{ marginLeft: props.isMobileLayout ? '0px' : '-5px', marginTop: '5px' }}
                    onClick={() => addMoreOnClick()}
                  > + Add more</button>
                </div> : null}
              {/* <Editor editorState={editorState} onChange={(state) => { console.log(state); setEditorState(state); }} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.isMobileQues} style={{ padding: '0 10px 10px 10px' }}>
        <div className="flex-space">
          {/*
          <div className={styles.questionFieldHeadingRt}>
            <img
              src={copy}
              style={{ cursor: "pointer" }}
              alt="copyImg"
              onClick={copyQuestion}
            />
          </div>
          */}
          <div className={styles.questionFieldSave}>
            <span
              className={styles.questionCancelText}
              onClick={props?.onClose}
            >
              {Constants.CANCEL}
            </span>
            <Button className={styles.questionSaveText} onClick={onSave}>
              {Constants.SAVE}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
EditQuestions.propTypes = propTypes;
export default EditQuestions;
